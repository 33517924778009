var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tabvertical" },
    [
      _c("c-tab", {
        attrs: {
          type: "vertical",
          tabItems: _vm.tabItems,
          height: _vm.tabHeight,
        },
        on: {
          "update:tabItems": function ($event) {
            _vm.tabItems = $event
          },
          "update:tab-items": function ($event) {
            _vm.tabItems = $event
          },
          "update:height": function ($event) {
            _vm.tabHeight = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (tab) {
              return [
                _c(tab.component, {
                  tag: "component",
                  attrs: {
                    height: _vm.tabHeight,
                    workPermit: _vm.workPermit,
                    supWorks: _vm.supWorks,
                    gridLoto: _vm.gridLoto,
                    gridHeavyEquip: _vm.gridHeavyEquip,
                    saveCall: _vm.saveCall,
                    isWriting: _vm.isWriting,
                  },
                  on: {
                    "update:workPermit": function ($event) {
                      _vm.workPermit = $event
                    },
                    "update:work-permit": function ($event) {
                      _vm.workPermit = $event
                    },
                    "update:supWorks": function ($event) {
                      _vm.supWorks = $event
                    },
                    "update:sup-works": function ($event) {
                      _vm.supWorks = $event
                    },
                    "update:gridLoto": function ($event) {
                      _vm.gridLoto = $event
                    },
                    "update:grid-loto": function ($event) {
                      _vm.gridLoto = $event
                    },
                    "update:gridHeavyEquip": function ($event) {
                      _vm.gridHeavyEquip = $event
                    },
                    "update:grid-heavy-equip": function ($event) {
                      _vm.gridHeavyEquip = $event
                    },
                    "update:saveCall": function ($event) {
                      _vm.saveCall = $event
                    },
                    "update:save-call": function ($event) {
                      _vm.saveCall = $event
                    },
                  },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.tab,
          callback: function ($$v) {
            _vm.tab = $$v
          },
          expression: "tab",
        },
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }